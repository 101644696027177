.ag-header {
  --ag-header-background-color: rgba(45, 171, 207, 0.1);
}

.ag-row-odd {
  --ag-odd-row-background-color: rgba(45, 171, 207, 0.15);
}

.ag-row-hover {
  --ag-row-hover-color: rgba(45, 171, 207, 0.5);
}

.ag-row-selected {
  --ag-selected-row-background-color: rgba(27, 131, 186, 1);
  color: #fff !important;
}


.ag-header.arard {
  --ag-header-background-color: rgb(193, 236, 253);
}

.ag-row-odd.arard {
  --ag-odd-row-background-color: rgb(193, 236, 253)
}

.ag-row-hover.arard {
  --ag-row-hover-color: rgba(45, 171, 207, 0.5);
}

.ag-row-selected.arard {
  --ag-selected-row-background-color: rgba(27, 131, 186, 1);
  color: #fff !important;
}